<template>
  <PageContainer>
    <template v-slot:header>
      <v-col>
        <span class="heading-2">Organization {{ organization.appName || organization.brandName }}</span>
      </v-col>
      <v-col class="justify-end d-flex">
        <IconicButton
          v-if="isOnHealthcareService"
          left-icon="mdi-plus"
          lite
          text="Add healthcare service"
          @onClick="addHealthcareService"
        />
        <IconicButton
          v-if="isOnPractitionerRoles"
          left-icon="mdi-plus"
          lite
          text="Add role"
          @onClick="addPractitionerRole"
        />
      </v-col>
    </template>
    <Tabs :tabs="tabs" :value="activeTab" />
    <router-view></router-view>
  </PageContainer>
</template>
<script>
import { mapActions, mapState } from "pinia";

import Tabs from "@/components/shared/Tabs.vue";
import IconicButton from "@/components/uikit/IconicButton.vue";
import PageContainer from "@/components/uikit/PageContainer.vue";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { useOrganizationStore } from "@/pinia-store/organization";
import { usePractitionerRoleStore } from "@/pinia-store/practitionerRole";
import {
  linkToOrganizationView,
  linkToOrganizationViewHealthcareServices,
  linkToOrganizationViewPractitioners,
  linkToOrganizationViewSchedule,
} from "@/router/linkGenerator";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "OrganizationIndex",
  data() {
    return {
      organizationId: null,
    };
  },
  components: { Tabs, IconicButton, PageContainer },
  computed: {
    ...mapState(useOrganizationStore, ["organizations", "isLoading", "organization"]),
    tabs() {
      return [
        {
          text: "General info",
          to: linkToOrganizationView(this.organizationId),
        },
        {
          text: "Schedule",
          to: linkToOrganizationViewSchedule(this.organizationId),
        },
        {
          text: "Healthcare Services",
          to: linkToOrganizationViewHealthcareServices(this.organizationId),
        },
        {
          text: "Practitioners",
          to: linkToOrganizationViewPractitioners(this.organizationId),
        },
      ];
    },
    activeTab() {
      console.log(this.$route.path);
      return this.tabs.findIndex((tab) => tab.to === this.$route.path);
    },
    isOnHealthcareService() {
      return this.$route.name === routesEnum.organizationHealthcareServices;
    },
    isOnPractitionerRoles() {
      return this.$route.name === routesEnum.organizationPractitioners;
    },
  },
  methods: {
    ...mapActions(useOrganizationStore, ["getOrganizations", "setForm", "setOrganization"]),
    ...mapActions(useHealthcareServiceStore, { setServiceForm: "setForm" }),
    ...mapActions(usePractitionerRoleStore, { setPractitionerRoleForm: "setForm" }),
    addHealthcareService() {
      this.setServiceForm({});
      console.log({ name: routesEnum.addHealthcareService, params: { organizationId: this.organizationId } });
      this.$router.push({ name: routesEnum.addHealthcareService, params: { organizationId: this.organizationId } });
    },
    addPractitionerRole() {
      this.setPractitionerRoleForm({});
      this.$router.push({
        name: routesEnum.addOrganizationPractitionerRoles,
        params: { organizationId: this.organizationId },
      });
    },
    edit(item) {
      this.setForm(item);
      this.$router.push({
        name: routesEnum.addOrganization,
        params: { id: item.id },
      });
    },

    viewSchedule(item) {
      this.setOrganization(item);
      this.$router.push({
        name: routesEnum.organizationSchedule,
        params: { organizationId: item.id },
      });
    },
  },
  beforeMount() {
    this.organizationId = this.$route.params.organizationId;
  },
  async mounted() {
    await this.getOrganizations();
    console.log("Organization mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-item-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
}
</style>
